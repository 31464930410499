/* global $ */

$(function () {
    "use strict";
    $('.planetary-illustration-object').on('mouseover', function () {
        if ($(this).hasClass('active')) {
            return false;
        }
        const index = $(this).index();
        $('.planetary-illustration-object').removeClass('active');
        $(this).addClass('active');
        $('.planetary-description-active').removeClass('planetary-description-active');
        $('.planetary-description').eq(index).addClass('planetary-description-active');
    });
});


$(function () {
    "use strict";
    $('.order-form-item').on('click', function () {
        $(this).toggleClass('order-form-item-selected');
        return false;
    });
});


$(function () {
    "use strict";
    $('.js-faq-expand-control').on('click', function () {
        const $container = $(this).closest('li');
        $('.faq-questions-item-expanded').not($container).removeClass('faq-questions-item-expanded');
        $container.toggleClass('faq-questions-item-expanded');
        return false;
    });
});


$(function () {
    "use strict";
    $('.js-menu-expand-control').on('click', function () {
        $('.menu').removeClass('menu-collapsed');
        return false;
    });
    $('.js-menu-collapse-control').on('click', function () {
        $('.menu').addClass('menu-collapsed');
        return false;
    });
});


$(function () {
    "use strict";
    $('.js-process-navigation-link').on('click', function () {
        $(this).closest('.process-navigation').toggleClass('process-navigation-expanded');
        $('.process-navigation-link-active').removeClass('process-navigation-link-active');
        $(this).addClass('process-navigation-link-active');
        return false;
    });
    $('.js-process-timeline-left').on('click', function () {
        // console.log('click');
        $('.process-timeline, .process-grid').each(function () {
            let offset = $(this).data('offset') ? $(this).data('offset') : 0;
            // var maxOffset = $(this).children().length;
            if (offset > 0) {
                offset--;
            }
            $(this).data('offset', offset);
            $(this).css({transform: 'translate(-' + (offset * 100) + '%, 0)'});
        });
        return false;
    });
    $('.js-process-timeline-right').on('click', function () {
        $('.process-timeline, .process-grid').each(function () {
            let offset = $(this).data('offset') ? $(this).data('offset') : 0;
            const maxOffset = $(this).children().length;
            if (offset < maxOffset - 1) {
                offset++;
            }
            $(this).data('offset', offset);
            $(this).css({transform: 'translate(-' + (offset * 100) + '%, 0)'});
        });
        return false;
    });

    $(window).on('resize', function () {
        const $target = $('.process-timeline, .process-grid');
        $target.css({transform: 'translate(0, 0)'});
    });
});

